export const GOOGLE_LOGIN_GROUP = `${process.env.USER_POOL}_Google`;
export const FACEBOOK_LOGIN_GROUP = `${process.env.USER_POOL}_Facebook`;
export const ADMIN_GROUP = "Admins";
export const EDITOR_GROUP = "Editors";
export const USER_GROUP = "Users";
export const CREATOR_GROUP = "Creators";

export const ROLE_GROUPS = [
  ADMIN_GROUP,
  EDITOR_GROUP,
  CREATOR_GROUP,
  USER_GROUP,
] as const;
export const LOGIN_GROUPS = [GOOGLE_LOGIN_GROUP, FACEBOOK_LOGIN_GROUP] as const;
export const SPECIAL_GROUPS = [...ROLE_GROUPS, ...LOGIN_GROUPS] as const;
