import CognitoUserAttrs from "api/cognito/CognitoUserAttrs";
import React, { FC, lazy, useCallback, useEffect, useState } from "react";

const WelcomeModal = lazy(() =>
  import("../../../organisms/Modal/WelcomeModal/WelcomeModal"),
);

interface Props {
  user: null | CognitoUserAttrs;
}

const WelcomeModalOpener: FC<Props> = ({ user }) => {
  const [open, setOpen] = useState(true);
  const [render, setRender] = useState(false);
  useEffect(() => {
    setRender(user?.displayWelcomeModal() || false);
  }, [user?.sub]);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  return <>{render && <WelcomeModal open={open} close={close} />}</>;
};
export default WelcomeModalOpener;
