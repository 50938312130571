/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import Cognito from "api/cognito/Cognito";
import CognitoUserAttrs from "api/cognito/CognitoUserAttrs";
import {
  IBusinessEntityDisplay,
  IBusinessEntityForm,
} from "api/types/BusinessEntity";
import { LatviesiUserType } from "api/cognito/interfaces";
import { getMyUserGroups } from "api/userApi";
import { withRouter, RouteComponentProps } from "react-router-dom";
import WelcomeModalOpener from "components/organisms/Modal/WelcomeModal/WelcomeModalOpener";
import { ISignUpResult } from "amazon-cognito-identity-js";

export interface IEditableCognitoAttrs {
  name: string;
  familyName: string;
  selfEmployed: string;
  userType: Array<LatviesiUserType>;
  picture: string;
}
interface IAuthContextState {
  user: CognitoUserAttrs | null;
  orgData: IBusinessEntityDisplay[];
  loading: boolean;
}

export interface IAuthContext extends IAuthContextState {
  updateOrgCache: (org: IBusinessEntityForm) => void;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  refresh: () => Promise<void>;
  token: () => Promise<string>;
  register: (
    username: string,
    password: string,
    attrs: {
      name: string;
      familyName: string;
      picture?: string;
    },
  ) => Promise<ISignUpResult | undefined>;
  confirmRegistration: (username: string, code: string) => Promise<void>;
  resendConfirmationCode: (username: string) => Promise<void>;
  forgotPassword: (username: string) => Promise<void>;
  changePasswordViaCode: (
    username: string,
    password: string,
    code: string,
  ) => Promise<void>;
  deleteUser: (username: string, password: string) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  updateAttributes: (attrs: Partial<IEditableCognitoAttrs>) => Promise<void>;
}
export const AuthContext = React.createContext<IAuthContext | null>(null);

/**
 * Wrappers for Cognito functions that provide
 * that user and orgData state is up to date
 */
class AuthContextProvider extends Component<
  {} & RouteComponentProps,
  IAuthContextState
> {
  state: IAuthContextState = {
    user: null,
    orgData: [],
    loading: true,
  };

  cognito: Cognito | undefined;

  async componentDidMount() {
    this.cognito = await Cognito.init();
    const { location, history } = this.props;
    const { hash } = location;
    try {
      // This checks if existing session is valid / refreshable
      let user = await this.cognito.getCurrUserAttrs();

      if (
        user &&
        user.minutesTilExpiry() < 60 &&
        user.identityProvider() !== "Cognito"
      ) {
        // WORKAROUND for soon expiring social sessions
        throw new Error("Social session too short");
      } else if (user) {
        this.setState({ user, loading: false });
        return;
      }

      if (hash) {
        this.cognito.signInSocial(hash);
        user = await this.cognito.getCurrUserAttrs();

        this.setState({ user, loading: false }, () => {
          history.replace({ ...location, hash: "" });
        });
      } else {
        this.setState({ user: null, loading: false });
      }
    } catch (err) {
      console.error("Failed to retrieve user session", err);
      this.cognito.signOut();
      this.setState({ user: null, loading: false }, () => {
        if (hash) {
          history.replace({ ...location, hash: "" });
        }
      });
    }
  }

  async componentDidUpdate(_: any, prevState: IAuthContextState) {
    // Taking care of keeping orgdata and usergroups in sync
    const { user } = this.state;
    const currOrgs = user?.organizations() || [];
    const prevOrgs = prevState.user?.organizations() || [];
    if (currOrgs?.length === prevOrgs?.length) return;

    if (!user) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ orgData: [] });
      return;
    }

    const idToken = await this.handleGetToken();
    let orgData = [];
    try {
      const { items } = await getMyUserGroups(idToken);
      orgData = items;
    } catch (err) {
      console.error("Failed to get usergroups", err);
    }
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ orgData });
  }

  /*
   * WILL THROW
   */
  handleLogIn = async (username: string, password: string) => {
    try {
      this.setState({ loading: true });
      await this.cognito?.signOut();
      await this.cognito?.signIn(username, password);
      const attrs = await this.cognito?.getCurrUserAttrs();
      if (!attrs) {
        this.cognito?.signOut();
        throw new Error("Failed to get User Attributes");
      }
      this.setState({ user: attrs, loading: false });
    } catch (err) {
      this.setState({ user: null, loading: false });
      throw err;
    }
    await this.cognito?.getCurrUserAttrs();
  };

  handleLogout = () => {
    this.cognito?.signOut();
    this.setState({ user: null });
  };

  /*
   * WILL THROW
   */
  handleRefresh = async () => {
    if (!this.cognito) throw new Error("Init cognito first");
    await this.cognito.refreshSession();
    try {
      const user = await this.cognito.getCurrUserAttrs();
      this.setState({ user, loading: false });
    } catch (err) {
      this.setState({ user: null, loading: false });
    }
  };

  handleGetToken = async () => {
    const token = await this.cognito?.getCurrentIdJwt();
    return token || "";
  };

  handleRegister = async (
    username: string,
    password: string,
    attrs: {
      name: string;
      familyName: string;
      picture?: string;
    },
  ) => {
    try {
      this.setState({ loading: true });
      const res = await this.cognito?.RegisterUser(username, password, attrs);
      this.setState({ loading: false });
      return res;
    } catch (err) {
      this.setState({ loading: false });
      throw err;
    }
  };

  handleConfirmRegistration = async (username: string, code: string) => {
    try {
      this.setState({ loading: true });
      await this.cognito?.ConfirmRegistration(username, code);
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      throw err;
    }
  };

  handleResendConfirmationCode = async (username: string) => {
    try {
      this.setState({ loading: true });
      await this.cognito?.ResendCode(username);
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      throw err;
    }
  };

  handleForgotPassword = async (username: string) => {
    try {
      this.setState({ loading: true });
      await this.cognito?.ForgotPassword(username);
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      throw err;
    }
  };

  handleChangePasswordViaCode = async (
    username: string,
    password: string,
    code: string,
  ) => {
    try {
      this.setState({ loading: true });
      await this.cognito?.ChangePasswordViaCode(username, password, code);
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      throw err;
    }
  };

  handleDeleteUser = async (username: string, password: string) => {
    try {
      this.setState({ loading: true });
      await this.cognito?.DeleteUser(username, password);
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      throw err;
    }
  };

  handleChangePassword = async (oldPassword: string, newPassword: string) => {
    try {
      this.setState({ loading: true });
      await this.cognito?.ChangePassword(oldPassword, newPassword);
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      throw err;
    }
  };

  handleUpdateAttributes = async (attrs: Partial<IEditableCognitoAttrs>) => {
    try {
      this.setState({ loading: true });
      await this.cognito?.UpdateAttributes(attrs);
      const user = (await this.cognito?.getCurrUserAttrs()) || null;
      this.setState({ user, loading: false });
    } catch (err) {
      this.setState({ loading: false });
      throw err;
    }
  };

  handleUpdateOrg = (entity: IBusinessEntityForm) => {
    this.setState((prev) => {
      const userGroups = prev.orgData;
      if (!userGroups) return prev;
      const newOrgId = userGroups.findIndex(
        (item) => item.slug === entity.slug,
      );

      if (newOrgId === -1) return prev;

      const orgClone = JSON.parse(
        JSON.stringify(userGroups),
      ) as IBusinessEntityDisplay[];

      orgClone.splice(newOrgId, 1, entity);

      return {
        ...prev,
        orgData: orgClone,
      };
    });
  };

  render() {
    const { user, orgData, loading } = this.state;
    const { children } = this.props;

    return (
      <AuthContext.Provider
        value={{
          loading,
          user,
          orgData,
          login: this.handleLogIn,
          logout: this.handleLogout,
          refresh: this.handleRefresh,
          token: this.handleGetToken,
          register: this.handleRegister,
          confirmRegistration: this.handleConfirmRegistration,
          resendConfirmationCode: this.handleResendConfirmationCode,
          forgotPassword: this.handleForgotPassword,
          changePasswordViaCode: this.handleChangePasswordViaCode,
          deleteUser: this.handleDeleteUser,
          changePassword: this.handleChangePassword,
          updateAttributes: this.handleUpdateAttributes,
          updateOrgCache: this.handleUpdateOrg,
        }}
      >
        <WelcomeModalOpener user={user} />
        {children}
      </AuthContext.Provider>
    );
  }
}

export default withRouter(AuthContextProvider);
