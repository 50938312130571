enum definedLocales {
  latvian = "LV",
  english = "EN",
  german = "DE",
}

export const isDefinedLocale = (
  x: string | definedLocales,
): x is definedLocales =>
  Object.values(definedLocales).some((value) => x === value);

export default definedLocales;
