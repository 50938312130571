import React from "react";
import { ButtonProps } from "semantic-ui-react";

import {
  BaseButton,
  ActionButton,
  PrimaryButton,
  MinimalActionButton,
  SocialButton,
  BasicButton,
  FooterButton,
  MiniButton,
} from "./SimpleButtonStyles";

export enum definedButtonTypes {
  primary = "primary",
  submit = "submit",
  secondary = "secondary",
  action = "action",
  miniAction = "miniAction",
  basic = "basic",
  default = "default",
  social = "social",
  footer = "footer",
  mini = "mini",
}

export interface SimpleButtonProps {
  id: string;
  variant?: definedButtonTypes;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  animated?: boolean;
  disabled?: boolean;
  floated?: ButtonProps["floated"];
  href?: string;
  target?: string;
  fluid?: boolean;
  icon?: ButtonProps["icon"];
  initialPadding?: boolean;
  loading?: boolean;
  type?: "submit" | "button";
}

const SimpleButton: React.FC<SimpleButtonProps> = ({
  id,
  children,
  variant,
  onClick,
  animated,
  disabled,
  href,
  target,
  fluid,
  icon,
  loading,
  initialPadding,
  type,
  floated,
}) => {
  let CurrentButton = BaseButton;
  if (variant === definedButtonTypes.miniAction) {
    CurrentButton = MinimalActionButton;
  } else if (variant === definedButtonTypes.action) {
    CurrentButton = ActionButton;
  } else if (variant === definedButtonTypes.primary) {
    CurrentButton = PrimaryButton;
  } else if (variant === definedButtonTypes.social) {
    CurrentButton = SocialButton;
  } else if (variant === definedButtonTypes.basic) {
    CurrentButton = BasicButton;
  } else if (variant === definedButtonTypes.footer) {
    CurrentButton = FooterButton;
  } else if (variant === definedButtonTypes.mini) {
    CurrentButton = MiniButton;
  }
  return (
    <CurrentButton
      id={id}
      onClick={onClick}
      type={type}
      animated={animated ? "vertical" : undefined}
      disabled={disabled}
      href={href}
      target={target}
      fluid={fluid}
      icon={icon}
      floated={floated}
      initialpadding={initialPadding ? 1 : 0}
      loading={loading}
    >
      {children}
    </CurrentButton>
  );
};
SimpleButton.defaultProps = {
  type: "button",
};
export default SimpleButton;
