import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ENG from "./en.json";
import LV from "./lv.json";
import DE from "./de.json";
import KEYWORD_LV from "./keywords.json";
import KEYWORD_ENG from "./keywordsEN.json";
import KEYWORD_DE from "./keywordsDE.json";
import CATEGORY_LV from "./categories.json";
import CATEGORY_ENG from "./categoriesEN.json";
import CATEGORY_DE from "./categoriesDE.json";
import ORGCATEGORY_LV from "./orgCategories.json";
import ORGCATEGORY_ENG from "./orgCategoriesEN.json";
import ORGCATEGORY_DE from "./orgCategoriesDE.json";
import COUNTRY_LV from "./countries.json";
import COUNTRY_ENG from "./countriesEN.json";
import COUNTRY_DE from "./countriesDE.json";
import definedLocales from "./definedLocales";

const resources = {
  LV: {
    common: LV,
    country: COUNTRY_LV,
    keyword: KEYWORD_LV,
    orgCategory: ORGCATEGORY_LV,
    category: CATEGORY_LV,
  },
  EN: {
    common: ENG,
    country: COUNTRY_ENG,
    keyword: KEYWORD_ENG,
    orgCategory: ORGCATEGORY_ENG,
    category: CATEGORY_ENG,
  },
  DE: {
    common: DE,
    country: COUNTRY_DE,
    keyword: KEYWORD_DE,
    orgCategory: ORGCATEGORY_DE,
    category: CATEGORY_DE,
  },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    defaultNS: "common",
    lng: definedLocales.latvian,
    fallbackLng: definedLocales.english,
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV !== "production",
  });

export default i18n;
