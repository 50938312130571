import React, { ReactElement } from "react";
import { ButtonProps, Button } from "semantic-ui-react";
import { Moment } from "moment-timezone";
import { DateTime } from "luxon";

export const renderGridElements = (
  col: number | undefined,
  element: ReactElement,
  keyName: string,
) => {
  const generatedArray = Array(col)
    .map((_, i) => i * i)
    .fill(1);
  return generatedArray.map((val, idx) => {
    const key = `${idx}-${keyName}-loader`;
    return <React.Fragment key={key}>{element}</React.Fragment>;
  });
};

export const SemanticButtonCP: React.FC<ButtonProps> = ({
  children,
  ...props
}) => <Button {...props}>{children}</Button>;

export const getLocalTimezoneWithTime = () => {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDateTime = DateTime.utc();
  const browserDateTime = DateTime.now().setZone(browserTimeZone);

  const browserOffsetMinutes = browserDateTime.offset;
  const utcOffsetMinutes = utcDateTime.offset;
  const offsetDifferenceMinutes = browserOffsetMinutes - utcOffsetMinutes;

  const offsetDirection = offsetDifferenceMinutes >= 0 ? "+" : "-";
  const absoluteOffsetDifferenceMinutes = Math.abs(offsetDifferenceMinutes);
  const offsetHours = Math.floor(absoluteOffsetDifferenceMinutes / 60);
  const offsetMinutes = absoluteOffsetDifferenceMinutes % 60;

  const formattedTime = browserDateTime.toFormat("HH:mm");
  const formattedOffset = `${offsetDirection}${String(offsetHours).padStart(
    2,
    "0",
  )}:${String(offsetMinutes).padStart(2, "0")}`;
  return `${browserTimeZone} (GMT${formattedOffset}) ${formattedTime}`;
};

DateTime.local().setLocale("lv");

export const ConvertDateToUTC = (
  date: any,
  format: string,
  showTz?: boolean,
) => {
  const jsDate = new Date(date);
  const localeTimezone = DateTime.local().zoneName;

  // Get the current DateTime in the guessed timezone
  const currentDateTime = DateTime.local()
    .setZone(localeTimezone)
    .setLocale("lv");

  // Get the UTC offset in minutes
  const offsetMinutes = currentDateTime.offset;

  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMinutesRemaining = Math.abs(offsetMinutes) % 60;
  const offsetDirection = offsetMinutes >= 0 ? "+" : "-";
  const displayTimeOffset = `${offsetDirection}${String(offsetHours).padStart(
    2,
    "0",
  )}:${String(offsetMinutesRemaining).padStart(2, "0")}`;

  // Format the date in UTC and then convert to the local timezone
  const utcDate = DateTime.fromJSDate(jsDate).toUTC();
  const localDate = utcDate
    .setZone(localeTimezone)
    .setLocale("lv")
    .toFormat(format);

  if (format.includes("HH:mm") && showTz) {
    return `${localDate} ${localeTimezone} (${displayTimeOffset})`;
  }

  return localDate;
};

export const isSameDay = (dateFrom?: Moment, dateTo?: Moment) => {
  if (dateFrom && dateTo) {
    const date1 = ConvertDateToUTC(dateFrom, "DD");
    const date2 = ConvertDateToUTC(dateTo, "DD");
    if (date1 === date2) {
      return true;
    }
  }
  return false;
};
