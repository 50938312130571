import { CSSObject } from "styled-components";

import DynamicRefComponent from "../Primitives/DynamicRefComponent";
import { styledWithTheme } from "../../../styles/theme";

export interface StyledTypographyProps {
  align: CSSObject["textAlign"];
  color?: string;
  margin?: string;
}

export enum definedTypographyTypes {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  title1 = "title1",
  title2 = "title2",
  title3 = "title3",
  title4 = "title4",
  caption = "caption",
  caption2 = "caption2",
  smallCaption = "smallCaption",
  button1 = "button1",
  button2 = "button2",
  body = "body",
}

// TODO better theme typing
function StylTypography(type: definedTypographyTypes) {
  return styledWithTheme(DynamicRefComponent)<StyledTypographyProps>(
    ({ theme, align, color, margin }) => {
      const currentTheme = theme.typography[type];
      const currentColor = currentTheme.color;

      return {
        "&&&": {
          ...currentTheme,
          textAlign: align,
          color: color || currentColor,
          ...(margin ? { margin } : {}),
        },
      };
    },
  );
}

export const StyledH1 = StylTypography(definedTypographyTypes.h1);
export const StyledH2 = StylTypography(definedTypographyTypes.h2);
export const StyledH3 = StylTypography(definedTypographyTypes.h3);
export const StyledH4 = StylTypography(definedTypographyTypes.h4);
export const StyledH5 = StylTypography(definedTypographyTypes.h5);
export const StyledTitle1 = StylTypography(definedTypographyTypes.title1);
export const StyledTitle2 = StylTypography(definedTypographyTypes.title2);
export const StyledTitle3 = StylTypography(definedTypographyTypes.title3);
export const StyledTitle4 = StylTypography(definedTypographyTypes.title4);
export const StyledBody = StylTypography(definedTypographyTypes.body);
export const StyledButton1Text = StylTypography(definedTypographyTypes.button1);
export const StyledButton2Text = StylTypography(definedTypographyTypes.button2);
export const StyledCaption = StylTypography(definedTypographyTypes.caption);
export const StyledCaption2 = StylTypography(definedTypographyTypes.caption2);
export const StyledSmallCaption = StylTypography(
  definedTypographyTypes.smallCaption,
);
