import { Helmet } from "react-helmet";
import React, { createContext, useState, useCallback } from "react";

export type IScripts =
  | "Twitter"
  | "TikTok"
  | "Flickr"
  | "reddit"
  | "Instagram"
  | "Facebook";

export interface IScriptContext {
  addOrReloadScript: (script: IScripts) => void;
}

const ScriptContext = createContext<IScriptContext | null>(null);
export default ScriptContext;

export const ScriptContextProvider: React.FC = ({ children }) => {
  const [scripts, setScripts] = useState<IScripts[]>([]);

  // TODO timer magic so I can ensure two reloads are not being triggered >.>
  const addOrReloadScript = useCallback((script: IScripts) => {
    setScripts((prevScripts) => {
      if (prevScripts.includes(script)) {
        if (!(process as any).browser) return prevScripts;
        if (!window) return prevScripts;

        switch (script) {
          case "Instagram": {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */

            if (window.instgrm !== undefined) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */

              instgrm.Embeds.process();
            }
            break;
          }
          case "Facebook": {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */

            if (window.FB !== undefined) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */
              FB.XFBML.parse();
              //   FB.XFBML.parse(document.getElementById('foo'));
            }
            break;
          }
          case "Twitter": {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */

            if (window.twttr !== undefined) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */

              twttr.widgets.load();
            }
            break;
          }
          case "TikTok": {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */

            if (window.reload_tiktokEmbed !== undefined) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */

              reload_tiktokEmbed();
            }
            break;
          }
          default:
            break;
        }

        return prevScripts;
      }

      return [...prevScripts, script];
    });
  }, []);

  return (
    <ScriptContext.Provider value={{ addOrReloadScript }}>
      <Helmet>
        {scripts.includes("Facebook") && <div id="fb-root" key="FB-id" />}
        {scripts.includes("Facebook") && (
          <script
            key="FB"
            async
            defer
            src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0&appId=485439424813685&autoLogAppEvents=1"
            crossOrigin="anonymous"
            nonce="2FEHf34R"
          />
        )}
        {scripts.includes("Twitter") && (
          <script
            key="twttr"
            async
            src="https://platform.twitter.com/widgets.js"
          />
        )}
        {scripts.includes("Instagram") && (
          <script
            key="instgrm"
            async
            defer
            src="https://platform.instagram.com/en_US/embeds.js"
          />
        )}
        {scripts.includes("reddit") && (
          <script
            key="embedly"
            async
            src="https://embed.redditmedia.com/widgets/platform.js"
          />
        )}
        {scripts.includes("Flickr") && (
          <script
            key="FlickrEmbedr"
            async
            src="https://embedr.flickr.com/assets/client-code.js"
          />
        )}
        {scripts.includes("TikTok") && (
          <script
            key="tiktokEmbed"
            async
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
                function reload_tiktokEmbed() {
                  var head= document.getElementsByTagName('head')[0];
                  var script= document.createElement('script');
                  script.async = true;
                  script.src= 'https://www.tiktok.com/embed.js';
                  head.appendChild(script);
                };
                reload_tiktokEmbed();
              `,
            }}
          />
        )}
      </Helmet>
      {children}
    </ScriptContext.Provider>
  );
};
