import { LoadScriptProps } from "@react-google-maps/api";
import IDropDownOption from "interfaces/IDropDownOption";
import { useTranslation } from "react-i18next";

export const DEFAULT_LIMIT = 12;
export const MAP_LIMIT = 1000;
export const COORDINATES_ARE_EQUAL_WITHIN = 0.000009;
export const GOOGLE_LIBRARIES: LoadScriptProps["libraries"] = ["places"];
export const TILDE_ERROR = "tildeError";

export const useLocalizedTypeOpts = (): IDropDownOption[] => {
  const { t } = useTranslation();
  const typeFilters = [
    "jaunumi",
    "notikumi",
    "attelu-galerijas",
    "video-galerijas",
    "piedavajumi",
    "organizacijas",
    "uznemumi",
  ];

  return typeFilters.map((type) => ({
    value: type,
    key: type,
    text: t(`Filter.TypeOpts.${type}`),
  }));
};
