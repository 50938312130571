/* eslint-disable camelcase */
export const BooleanFromAnything = (
  anything: string | number | boolean | null | undefined,
): boolean => {
  if (anything === "true") return true;
  if (anything === "1") return true;
  if (anything === 1) return true;
  if (anything === true) return true;

  return false;
};

export const parseStringOrObject = (
  maybeIamJson: string,
): string | Record<any, any> => {
  let orphan = maybeIamJson;
  try {
    orphan = JSON.parse(maybeIamJson);
  } catch (err) {
    // hes not j's son
  }
  return orphan;
};

export const generateVerification = () => {
  let verification = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 32; i += 1) {
    verification += possible.charAt(
      Math.floor(Math.random() * possible.length),
    );
  }
  return verification;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(""),
  );

  return JSON.parse(jsonPayload);
};

interface IOAuthRes {
  id_token: string;
  access_token: string;
  refresh_token: undefined;
}
// TODO set up social login refresh
export const parseOAuthResult = (token: string): IOAuthRes => {
  try {
    const trimmed = token.replace("#", "");
    const parsed = trimmed
      .split("&")
      .reduce(
        (obj, cur) => ({ ...obj, [cur.split("=")[0]]: cur.split("=")[1] }),
        {},
      );

    const { id_token, access_token, refresh_token } = parsed as any;

    if (!id_token) throw new Error("Invalid token form");
    if (!access_token) throw new Error("Invalid token form");
    // Gotcha - social logins dont have it
    // if (!refresh_token) throw new Error("Invalid token form");

    return {
      id_token,
      access_token,
      refresh_token,
    };
  } catch (err) {
    throw new Error("Invalid token form");
  }
};

export function PasswordMatchesRules(password: string) {
  //  match 1 Capital letter, 1 normal letter, 1 number, at least 7  length
  const regexMatch = password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/);
  return regexMatch && password.length > 6;
}
