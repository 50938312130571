import React from "react";

import definedLocales from "../locales/definedLocales";

// eslint-disable-next-line @typescript-eslint/no-empty-function
function placeHolder() {}

export type TLocale = {
  defaultLocale: definedLocales;
  currentLocale: definedLocales;
  setLocale: (locale: definedLocales) => void;
  setTranslationError: () => void;
};

export interface IGlobalStateContext {
  locale: TLocale;
}

const GlobalStateContext = React.createContext<IGlobalStateContext>({
  locale: {
    defaultLocale: definedLocales.latvian,
    currentLocale: definedLocales.latvian,
    setLocale: placeHolder,
    setTranslationError: placeHolder,
  },
});

export default GlobalStateContext;
