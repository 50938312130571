import { MouseEvent } from "react";
import { IconProps } from "semantic-ui-react";
import slugify from "slugify";
import { INewsDisplay } from "api/types/NewsEntity";
import { IBusinessEntityDisplay } from "api/types/BusinessEntity";
import IOrganizationInfo from "api/types/utils/IOrganizationInfo";

import { ImageCloudUrl } from "../api/api";

export const readBufferFromFile = async (file: File): Promise<Buffer> => {
  const result = await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });

  if (result instanceof ArrayBuffer) {
    return Buffer.from(new Uint8Array(result));
  }

  throw new Error("result is not a buffer :(");
};

export function openShareDialog(
  e: MouseEvent,
  provider: { icon: IconProps["name"]; url: string; name: string },
) {
  e.preventDefault();
  window.open(provider.url, "share-dialog", "width=626,height=436,toolbar=0");
}

export function YouTubeParser(url?: string) {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url?.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  }

  throw new Error("Not a valid youtube url");
}

export const readStringFromFile = async (
  file: File | undefined | Blob,
): Promise<string | undefined> => {
  if (file === undefined) return undefined;

  const result = await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onabort = () => {
      reject(new Error("file reading has aborted"));
    };
    reader.onerror = () => {
      reject(new Error("file reading has failed"));
    };
    reader.onload = () => {
      resolve(reader.result);
    };
  });

  if (typeof result === "string") return result;

  throw new Error("readStringFromFile received invalid result");
};

export const slugifyWord = (word: string) =>
  slugify(word, {
    replacement: "-",
    remove: /[*+~,.()%"/'!?:@]/g,
    lower: true,
  });

export function PasswordMatchesRules(password: string) {
  //  match 1 Capital letter, 1 normal letter, 1 number, at least 7  length
  const regexMatch = password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/);
  return regexMatch && password.length > 6;
}

export function CheckUniqueKeyWord(source: string[], target: string[]) {
  target.forEach((t) => {
    if (!source.includes(t)) {
      source.push(t);
    }
  });
}

export const refreshPage = () => {
  window.location.reload(false);
};

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export function scrollToTop() {
  return window.scrollTo({ top: 0, behavior: "smooth" });
}

// intended
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSomeEnum = <T>(e: T) => (token: any): token is T[keyof T] =>
  Object.values(e).includes(token as T[keyof T]);

export const imageDimensions = async (
  link: string,
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image();

    // the following handler will fire after the successful parsing of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject(new Error("There was some problem with the image."));
    };

    img.src = link;
  });

export async function findBestThumbnailFromYoutubeLink(link: string) {
  let youtubeValue = "";
  try {
    youtubeValue = YouTubeParser(link);
  } catch (err) {
    youtubeValue = "";
  }

  const googleAPI = `https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet&id=${youtubeValue}`;

  const response = await fetch(googleAPI);
  const video = await response.json();

  // https://developers.google.com/youtube/v3/docs/videos#snippet.thumbnails
  // default – The default thumbnail image. The default thumbnail for a video – or a resource that refers to a video, such as a playlist item or search result – is 120px wide and 90px tall. The default thumbnail for a channel is 88px wide and 88px tall.
  // medium – A higher resolution version of the thumbnail image. For a video (or a resource that refers to a video), this image is 320px wide and 180px tall. For a channel, this image is 240px wide and 240px tall.
  // high – A high resolution version of the thumbnail image. For a video (or a resource that refers to a video), this image is 480px wide and 360px tall. For a channel, this image is 800px wide and 800px tall.
  // standard – An even higher resolution version of the thumbnail image than the high resolution image. This image is available for some videos and other resources that refer to videos, like playlist items or search results. This image is 640px wide and 480px tall.
  // maxres – The highest resolution version of the thumbnail image. This image size is available for some videos and other resources that refer to videos, like playlist items or search results. This image is 1280px wide and 720px tall.
  let { url } = video.items[0].snippet.thumbnails.default;
  if (video.items[0].snippet.thumbnails.maxres?.url) {
    url = video.items[0].snippet.thumbnails.maxres.url;
  } else if (video.items[0].snippet.thumbnails.high.url) {
    url = video.items[0].snippet.thumbnails.high.url;
  }
  return url || "https://img.youtube.com/vi/default.jpg";
}

export function getThumbnailLink(link?: string) {
  if (!link) return undefined;
  const imgKey = link.split("/").pop();
  return `${ImageCloudUrl}/fit-in/450x220/filters:quality(80)/${imgKey}`;
}
export function getLargeThumbnailLink(link?: string) {
  if (!link) return undefined;
  const imgKey = link.split("/").pop();
  return `${ImageCloudUrl}/fit-in/650x420/filters:quality(80)/${imgKey}`;
}
export function getSquaredThumbnailLink(link?: string) {
  if (!link) return undefined;
  const imgKey = link.split("/").pop();
  return `${ImageCloudUrl}/fit-in/400x400/filters:quality(80)/${imgKey}`;
}

export function getOptimizedImage(link?: string) {
  if (!link) return undefined;
  const imgKey = link.split("/").pop();
  return `${ImageCloudUrl}/fit-in/850x500/filters:quality(80)/${imgKey}`;
}

export function ParseFilterErrorMessage(query: string) {
  if (query) return query;

  return "yo te bus errucis";
}

function defaultSort(toSortArray: Record<string, any>[], sortKey: string) {
  toSortArray.sort((a, b) => {
    if (a[sortKey] && b[sortKey]) {
      if (a[sortKey] > b[sortKey]) {
        return 1;
      }

      if (a[sortKey] < b[sortKey]) {
        return -1;
      }
    }
    return 0;
  });
  return toSortArray;
}

function sortByBoolean(toSortArray: Record<string, any>[], sortKey: string) {
  toSortArray.sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a[sortKey] === b[sortKey] ? 0 : a[sortKey] ? -1 : 1,
  );
  return toSortArray;
}

export function SortCardsByDate(cards: INewsDisplay[]) {
  const sortedCards = defaultSort(cards, "fromDate");
  return sortedCards as INewsDisplay[];
}

export function OrderOrganizations(cards: IBusinessEntityDisplay[]) {
  const alphabetical = defaultSort(cards, "title");
  const byCountry = sortByBoolean(alphabetical, "highlightedInCountry");
  const allCards = sortByBoolean(byCountry, "highlighted");
  return allCards as IBusinessEntityDisplay[];
}

export async function doesImageExist(path: string) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = path;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}

export function uriCheckerForOldArticles(uri: string) {
  if (uri.includes("/raksts/")) {
    return uri.replace("/raksts/", "/");
  }
  return false;
}

export function IsJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const urlWithProtocol = (web?: string) => {
  if (web) {
    const a = web.split("://")[0];
    if (a !== "http" && a !== "https") {
      return `http://${web}`;
    }
    return web;
  }
  return undefined;
};

export function mapOrgInfo(
  organizations: IBusinessEntityDisplay[],
): IOrganizationInfo[] {
  return organizations.map((org) => {
    const { slug, title, imageUrl, diasporeMedia } = org;
    return {
      slug,
      imageUrl,
      title,
      diasporeMedia,
    };
  });
}

export function unionBy<T extends unknown>(
  oldArray: Array<T>,
  replacements: Array<T>,
  key: string,
) {
  return oldArray
    .filter((aa: any) => !replacements.find((bb: any) => aa[key] === bb[key]))
    .concat(replacements);
}

export function equalWithin(
  firstNumber: number | null | undefined,
  secondNumber: number | null | undefined,
  range = 0,
) {
  if (!firstNumber || !secondNumber) return false;
  const upper = firstNumber <= secondNumber + range;
  const lower = firstNumber >= secondNumber - range;

  return upper && lower;
}

export const removeLinkProtocol = (url = "") => {
  let cleanUrl = url;
  if (cleanUrl.includes("https://") || cleanUrl.includes("http://")) {
    [, cleanUrl] = cleanUrl.split("//");
  }
  if (cleanUrl.includes("www.")) {
    return cleanUrl.split("www.")[1];
  }
  return cleanUrl;
};
