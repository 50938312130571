import { TILDE_ERROR } from "constants/important";
// TODO: Move to environment variables
export const AWSurl =
  "https://9y5r4t1jo2.execute-api.eu-central-1.amazonaws.com/PROD_V1/api";
export const BackendUrl =
  "https://clazi6m2wl.execute-api.eu-central-1.amazonaws.com/production";
// "http://localhost:3000/development";
// "https://0vslrcddzk.execute-api.eu-central-1.amazonaws.com/staging";
// "https://clazi6m2wl.execute-api.eu-central-1.amazonaws.com/production";
export const ImageCloudUrl = "https://d2uxl5q68iycnn.cloudfront.net";
export const singleData = "single?slug=";
export const redirectData = "redirect?slug=";
export enum definedRouteTypes {
  editorialNews = "jaunumi",
  diasporeNews = "mediju-jaunumi",
  organizationNews = "organizaciju-jaunumi",
  companyNews = "uznemumu-jaunumi",
  events = "notikumi",
  imageGallery = "attelu-galerijas",
  videoNews = "video-galerijas",
  offers = "piedavajumi",
  organizations = "organizacijas",
  organization = "organizacija",
  opinion = "viedoklis",
  regionNews = "regioni",
  search = "search",
  filter = "filter",
  dynamic = "dynamic",
  sidebar = "sidebar",
  upload = "upload",
  redirect = "redirect",
  companies = "uznemumi",
  company = "uznemum",
  selfEmployed = "profesionali",
  mediaLinks = "mediju-saites",
  mediaSources = "mediju-avoti",
  allLinks = "saites",
  allGalleries = "galerijas",
  madeInLatvia = "latvija-radits",
  latviansAroundWorld = "latvijapasaule",
  partnerOrganizations = "partneri",
  partnerOrganization = "partneris",
  exclusive = "medijs",
}

export enum definedFooterRoutes {
  contacts = "kontakti",
  about = "par-mums",
  tos = "lietosanas-noteikumi",
  forOrganizations = "organizacijam",
  forCompanies = "uznemumiem",
  partners = "atbalstitaji",
  nightingale = "lakstigala",
  instructions = "lietosanas-pamaciba",
}

// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PostSettings = (body: any, headers: Record<string, string> = {}) => ({
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  },
  body: JSON.stringify(body),
});

// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PutSettings = (body: any, headers: Record<string, string> = {}) => ({
  method: "PUT",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  },
  body: JSON.stringify(body),
});

// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DeleteSettings = (body: any, headers: Record<string, string> = {}) => ({
  method: "DELETE",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  },
  body: JSON.stringify(body),
});

const PostTildeSettings = (body: any) => ({
  method: "POST",
  headers: {
    "client-id": process.env.REACT_APP_TILDE_API_KEY,
    "Content-Type": "",
  },
  body: JSON.stringify(body),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GetSettings = (headers: Record<string, string> = {}) => ({
  method: "GET",
  headers: {
    Accept: "*/*",
    ...headers,
  },
});

export enum definedErrors {
  slugExists = "SLUG exists",
  noGalleryFound = "Gallery should contain at least one image.",
  incorrectPass = "Incorrect username or password.",
  repeat = "Repeat field should match.",
  passNoGuidelines = "Password should abide password guidelines.",
  noSlug = "slug doesn't exist",
  duplicate = "Duplicate",
  blacklistedSource = "Media source blacklisted",
}
export class ApiError extends Error {
  body: any;

  constructor(body: any) {
    super(`Fetch did not return OK status : ${JSON.stringify(body)}`);
    this.body = body;
  }
}
interface IApiSettings {
  method: string;
  headers?: any;
  body?: any;
}

export async function handleApi(
  url: string,
  settings: IApiSettings,
  skipResponseStream = false,
) {
  const response = await fetch(url, settings);
  if (skipResponseStream) {
    // this is necessary for presignS3 fetches
    if (response.status !== 200) {
      throw new ApiError(response);
    }

    return response;
  }
  const data = await response.json();
  if (response.status !== 200) {
    throw new ApiError(data);
  }
  if (data?.errorType || data?.errorMessage) {
    throw new Error(`Failed to fetch. Reason: ${data.errorMessage}`);
  }
  if (data?.body === definedErrors.slugExists) throw new Error(data.body);
  // ! TODO: implement error checking when stable and unified lambdas
  // check if error is necessary here as well, lambda will respond with 200 atm
  return data;
}

export function handleApiGet(
  url: string,
  headers: Record<string, string> = {},
) {
  return handleApi(url, GetSettings(headers));
}

// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleApiPost(
  url: string,
  body: any,
  headers: Record<string, string> = {},
) {
  return handleApi(url, PostSettings(body, headers));
}

export function handleApiDeletes(
  url: string,
  body: Record<string, any> = {},
  headers: Record<string, string> = {},
) {
  return handleApi(url, DeleteSettings(body, headers));
}
export function handleApiDelete(url: string, slug: string) {
  return handleApi(url, DeleteSettings({ slug }));
}
export function handleApiImageDelete(url: string, imageUrl: string) {
  return handleApi(url, DeleteSettings({ imageUrl }));
}

// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleApiPut(
  url: string,
  body: any,
  headers: Record<string, string> = {},
) {
  return handleApi(url, PutSettings(body, headers));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleTildeApiPost(url: string, body: any) {
  return handleApi(url, PostTildeSettings(body))
    .then((res) => res)
    .catch((err) => {
      console.error(err);
      return TILDE_ERROR;
    });
}
