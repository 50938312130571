import { getAllUserRatings, rateMediaSource } from "api/new/mediaLinkApi";
import { useAuthContext } from "hooks/useAuthorizedContext";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";

export interface IUserDataContext {
  isLoggedIn: boolean;
  getRatingFromId: (mediaSourceId: string) => { rating: number } | undefined;
  updateRatingFromId: (
    mediaSourceId: string,
    newRating: number,
  ) => Promise<void>;
}

const UserDataContext = React.createContext<IUserDataContext>({
  isLoggedIn: false,
  getRatingFromId: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateRatingFromId: async () => {},
});

export const UserDataContextProvider: React.FC = ({ children }) => {
  const { user, token } = useAuthContext();
  const { t } = useTranslation();
  const [ratings, setRatings] = useImmer(new Map());

  useEffect(() => {
    if (!user) return;
    async function promise() {
      try {
        const idToken = await token();
        const res = await getAllUserRatings(idToken);
        setRatings((draft) => {
          res.forEach((rating: any) => {
            draft.set(rating.mediaSourceId, rating);
          });
          return draft;
        });
      } catch (err) {
        console.error("Failed to call getAllUserRatings", err);
      }
    }
    promise();
  }, [user?.username]);

  const getRatingFromId = useCallback(
    (mediaSourceId) => ratings.get(mediaSourceId),
    [ratings, user],
  );

  const updateRatingFromId = useCallback(
    async (mediaSourceId: string, newRating: number) => {
      const idToken = await token();
      await rateMediaSource(idToken, newRating, mediaSourceId)
        .then(() => {
          setRatings((draft) => {
            draft.set(mediaSourceId, { rating: newRating });
            toast.success(t("MediaLinkForms.Messages.SourceRatedSuccessfully"));
            return draft;
          });
        })
        .catch(() =>
          toast.error("Kaut kas nogāja greizi! Mēģini vēlak vēlreiz!"),
        );
    },
    [token],
  );

  return (
    <UserDataContext.Provider
      value={{
        isLoggedIn: Boolean(user),
        getRatingFromId,
        updateRatingFromId,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataContext;
