/* eslint-disable no-console */
/* eslint-disable camelcase */
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import {
  authenticateUserPromise,
  deleteUserPromise,
  changePasswordPromise,
  confirmPasswordPromise,
  confirmRegistrationPromise,
  forgotPasswordPromise,
  getSessionPromise,
  refreshSessionPromise,
  resendConfirmationCodePromise,
  signUpPromise,
  updateAttributesPromise,
  getUserDataPromise,
} from "./promiseWrappers";
import CognitoUserAttrs from "./CognitoUserAttrs";
import {
  generateVerification,
  parseOAuthResult,
  PasswordMatchesRules,
} from "./helpers";

const USER_POOL_OPTS = {
  UserPoolId: "eu-central-1_FY9dnBI1u", // TODO ENV
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID || "",

  // DEV creds
  // UserPoolId: "eu-central-1_kaqz6Glco",
  // ClientId: "4mujbv4peb8g8svrvroee3jfim",

  // Does not really work as expected.
  // storage: Storage,
};

const SOCIAL_LOGIN_OPTS = {
  domain: "latviesi.auth.eu-central-1.amazoncognito.com",
  // domain: "latviesi-dev.auth.eu-central-1.amazoncognito.com",
  flow: "/oauth2/authorize",
  clientId: USER_POOL_OPTS.ClientId,
  type: "TOKEN",
  scope: "profile email openid aws.cognito.signin.user.admin",
  FbProvider: "Facebook",
  GProvider: "Google",
  // callback: `http://localhost:3000/`,
  callback: `https://www.latviesi.com/`,
};

/**
 * Workable list of Cognito function wrappers.
 * So you have no need to think about various parts original api consists of.
 */
class Cognito {
  private Pool: CognitoUserPool;

  private constructor(Pool: CognitoUserPool) {
    this.Pool = Pool;
  }

  /*
   * To initialize user pool with user you need to call initUser
   * This deals with Cognito internal logic, session refresh and cache clearance.
   */
  public static async init() {
    const pool = new CognitoUserPool(USER_POOL_OPTS);
    const user = pool.getCurrentUser();
    if (user) await Cognito.initUser(user);

    return new Cognito(pool);
  }

  /**
   * Gets user from cookies, refreshes its session and reads in new attribute data
   */
  private async getUserAndInitIt(): Promise<CognitoUser | null> {
    const user = this.Pool.getCurrentUser();
    if (user) await Cognito.initUser(user);

    return user;
  }

  private async getSession(): Promise<CognitoUserSession | null> {
    const user = this.Pool.getCurrentUser();
    if (!user) return null;
    return Cognito.initUser(user);
  }

  public async getCurrUserAttrs(): Promise<CognitoUserAttrs | null> {
    const session = await this.getSession().catch((err) =>
      console.error("Failed to getCurrUserAttrs", err),
    );
    if (!session) return null;

    const userToken = session.getIdToken().payload;

    // HACK VV because social logins don't refresh :(
    let extraAttrs = {};
    if (userToken["cognito:username"].length < 36) {
      const user = await this.getUserAndInitIt();
      const cookie = await getUserDataPromise(user as any);
      extraAttrs = CognitoUserAttrs.formatUserAttrsOutput(cookie);
    }
    // HACK ^^

    return new CognitoUserAttrs({ ...userToken, ...extraAttrs });
  }

  public getCurrentIdJwt = async (): Promise<string | undefined> => {
    const session = await this.getSession()
      .catch((err) => console.error("Failed to getCurrentIdJwt", err))
      .then((res) => res || null);
    return session?.getIdToken().getJwtToken();
  };

  public refreshSession = async () => {
    const user = await this.getUserAndInitIt();
    if (!user) throw new Error("No user to refresh.");
    const session = await this.getSession();
    if (!session?.isValid()) throw new Error("Failed to get new session");

    const token = session.getRefreshToken();
    if (!token) throw new Error("SOCIAL");
    return refreshSessionPromise(token, user);
  };

  public signIn = async (Username: string, Password: string) => {
    const user = await this.getUserAndInitIt();
    if (user) throw new Error("User already present...");
    const authDetails = new AuthenticationDetails({
      Username,
      Password,
    });
    const newUser = new CognitoUser({ Username, Pool: this.Pool });
    await authenticateUserPromise(newUser, authDetails);
  };

  public signOut = () => {
    const user = this.Pool.getCurrentUser();
    user?.signOut();
  };

  public ResendCode = async (Username: string) => {
    const user = new CognitoUser({ Username, Pool: this.Pool });
    await resendConfirmationCodePromise(user);
  };

  public signInSocial = (oAuth: string) => {
    let user = this.Pool.getCurrentUser();
    if (user) throw new Error("User already present.");

    const { access_token, refresh_token, id_token } = parseOAuthResult(oAuth);

    const IdToken = new CognitoIdToken({ IdToken: id_token });
    const AccessToken = new CognitoAccessToken({ AccessToken: access_token });
    const RefreshToken = new CognitoRefreshToken({
      RefreshToken: refresh_token || "",
    });

    const Username = IdToken.payload.sub; // social login usernames are weird

    user = new CognitoUser({ Username, Pool: this.Pool });

    const session = new CognitoUserSession({
      IdToken,
      AccessToken,
      RefreshToken,
    });

    return user.setSignInUserSession(session);
  };

  public RegisterUser = async (
    username: string,
    password: string,
    attrs: {
      name: string;
      familyName: string;
      picture?: string;
    },
  ) => {
    if (username.length <= 0) throw new Error("Username too short");
    if (!PasswordMatchesRules(password)) throw new Error("Insecure password");

    const CognitoUserAttributes = Object.keys(attrs)
      .filter((attr) => attrs[attr as keyof typeof attrs])
      .map(
        (attr) =>
          new CognitoUserAttribute({
            Name: CognitoUserAttrs.formatMap[attr as keyof typeof attrs],
            Value: attrs[attr as keyof typeof attrs] as string,
          }),
      );

    return signUpPromise(this.Pool, username, password, CognitoUserAttributes);
  };

  public ConfirmRegistration = async (Username: string, code: string) => {
    const user = new CognitoUser({ Username, Pool: this.Pool });
    return confirmRegistrationPromise(user, code);
  };

  public ChangePassword = async (oldPassword: string, newPassword: string) => {
    const user = await this.getUserAndInitIt();
    if (!user) throw new Error("Cannot reset password if no user");

    return changePasswordPromise(user, oldPassword, newPassword);
  };

  ForgotPassword = async (Username: string) => {
    const user = new CognitoUser({ Username, Pool: this.Pool });

    return forgotPasswordPromise(user);
  };

  ChangePasswordViaCode = async (
    Username: string,
    newPassword: string,
    code: string,
  ) => {
    const user = new CognitoUser({ Username, Pool: this.Pool });
    return confirmPasswordPromise(user, newPassword, code);
  };

  DeleteUser = async (Username: string, password: string) => {
    const user = await this.getUserAndInitIt();
    if (!user) throw new Error("No user found to delete");
    const authDetails = new AuthenticationDetails({
      Username,
      Password: password,
    });

    await authenticateUserPromise(user, authDetails);
    return deleteUserPromise(user);
  };

  UpdateAttributes = async (attrs: Record<string, any>) => {
    const user = await this.getUserAndInitIt();
    if (!user) throw new Error("Unable to authorize information update");

    const WHITELIST = [
      "userType",
      "selfEmployed",
      "familyName",
      "name",
      "picture",
    ] as const;
    const attributeKeys = Object.keys(attrs);
    attributeKeys.forEach((key: any) => {
      if (WHITELIST.includes(key)) return;
      throw new Error("Incompatible Attribute found");
    });
    const newAttributes = attributeKeys.map((key) => {
      const Value =
        typeof attrs[`${key}`] === "string"
          ? attrs[`${key}`]
          : JSON.stringify(attrs[`${key}`]);

      const Name = CognitoUserAttrs.formatMap[key as typeof WHITELIST[0]];

      return new CognitoUserAttribute({ Name, Value });
    });

    return updateAttributesPromise(user, newAttributes);
  };

  public static SignInWithFacebook() {
    const verification = generateVerification();
    sessionStorage.setItem("verification", verification);
    // Verification is never checked....
    const {
      domain,
      flow,
      clientId,
      type,
      scope,
      FbProvider,
      callback,
    } = SOCIAL_LOGIN_OPTS;
    window.location.href = `https://${domain}/${flow}?identity_provider=${FbProvider}&redirect_uri=${callback}&response_type=${type}&client_id=${clientId}&scope=${scope}&state=${verification}`;
  }

  public static SignInWithGoogle() {
    const verification = generateVerification();
    sessionStorage.setItem("verification", verification);
    // Verification is never checked....
    const {
      domain,
      flow,
      clientId,
      type,
      scope,
      GProvider,
      callback,
    } = SOCIAL_LOGIN_OPTS;
    window.location.href = `https://${domain}/${flow}?identity_provider=${GProvider}&redirect_uri=${callback}&response_type=${type}&client_id=${clientId}&scope=${scope}&state=${verification}`;
  }

  /**
   * AVOID! Does not refresh session!
   */
  public static getIdTokenFromStorage(): string {
    const keyPrefix = `CognitoIdentityServiceProvider.${USER_POOL_OPTS.ClientId}`;
    const userNameKey = `${keyPrefix}.LastAuthUser`;
    const username = localStorage.getItem(userNameKey);
    if (!username) return "";
    const idTokenKey = `${keyPrefix}.${username}.idToken`;
    const idToken = localStorage.getItem(idTokenKey);
    return idToken || "";
  }

  private static async initUser(user: CognitoUser) {
    return getSessionPromise(user)
      .then(async (session) => {
        await getUserDataPromise(user);
        return session;
      })
      .catch((err) => {
        console.error("Failed to initialize user", err);
        return null;
      });
  }
}

export default Cognito;
