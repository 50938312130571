/* eslint-disable prettier/prettier */

import { AD_TYPE, IAd, IStaticInfo, IOrgSlug } from "api/types/TODO";
import { RawDraftContentState } from "draft-js";

import { BackendUrl, definedFooterRoutes, handleApiGet } from "../api";

export async function getAd(slug: AD_TYPE): Promise<IAd> {
  return handleApiGet(`${BackendUrl}/get-item/ad/${slug}`);
}

export async function getKeywords(): Promise<string[]> {
  return handleApiGet(`${BackendUrl}/get-item/keywords`);
}

export async function getOrgSlugs(): Promise<IOrgSlug[]> {
  return handleApiGet(`${BackendUrl}/get-item/org-slugs`);
}

export async function getStaticPage(type: definedFooterRoutes.contacts): Promise<IStaticInfo[]>;
export async function getStaticPage(type: definedFooterRoutes         ): Promise<RawDraftContentState>;
export async function getStaticPage(type: definedFooterRoutes         ): Promise<RawDraftContentState | IStaticInfo[]> {
  return handleApiGet(`${BackendUrl}/get-item/static-page/${type}`);
}
