import * as React from "react";
import { CSSObject } from "styled-components";

import {
  StyledBody,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
  StyledTitle1,
  StyledTitle2,
  StyledTitle3,
  StyledTitle4,
  StyledButton1Text,
  StyledButton2Text,
  StyledCaption,
  StyledCaption2,
  StyledSmallCaption,
  definedTypographyTypes,
} from "./TypographyStyles";

import { TComponentRef } from "../Primitives/DynamicRefComponent";

export const definedTypography = definedTypographyTypes; // FIXME: why dis?
interface TypographyProps {
  align?: CSSObject["textAlign"];
  color?: string;
  as?: TComponentRef;
  type?: definedTypographyTypes;
  inline?: boolean;
  id?: string;
  margin?: string;
  className?: string;
}

const defaultTypeMapping: Record<definedTypographyTypes, TComponentRef> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  title1: "h4",
  title2: "h4",
  title3: "h4",
  title4: "h5",
  body: "p",
  caption: "span",
  caption2: "span",
  smallCaption: "span",
  button1: "span",
  button2: "span",
};

const Typography: React.FC<TypographyProps> = ({
  children,
  align,
  color,
  as: asComponent,
  type,
  id,
  margin,
  className,
}) => {
  let Component = StyledBody;
  if (type === definedTypography.button1) {
    Component = StyledButton1Text;
  } else if (type === definedTypography.button2) {
    Component = StyledButton2Text;
  } else if (type === definedTypography.caption) {
    Component = StyledCaption;
  } else if (type === definedTypography.caption2) {
    Component = StyledCaption2;
  } else if (type === definedTypography.smallCaption) {
    Component = StyledSmallCaption;
  } else if (type === definedTypography.h1) {
    Component = StyledH1;
  } else if (type === definedTypography.h2) {
    Component = StyledH2;
  } else if (type === definedTypography.h3) {
    Component = StyledH3;
  } else if (type === definedTypography.h4) {
    Component = StyledH4;
  } else if (type === definedTypography.h5) {
    Component = StyledH5;
  } else if (type === definedTypography.title1) {
    Component = StyledTitle1;
  } else if (type === definedTypography.title2) {
    Component = StyledTitle2;
  } else if (type === definedTypography.title3) {
    Component = StyledTitle3;
  } else if (type === definedTypography.title4) {
    Component = StyledTitle4;
  } else if (type === definedTypography.body) {
    Component = StyledBody;
  }

  const innerComponentRef =
    asComponent || defaultTypeMapping[type || definedTypography.body];

  return (
    <Component
      align={align}
      color={color}
      componentRef={innerComponentRef}
      id={id}
      margin={margin}
      className={className}
    >
      {children}
    </Component>
  );
};

Typography.defaultProps = {
  align: "left",
  type: definedTypography.body,
};

export default Typography;
