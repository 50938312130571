import { useContext } from "react";
import { AuthContext, IAuthContext } from "context/AuthContextProvider";

type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

type Ensure<T, K extends keyof T> = T & RequiredNotNull<Pick<T, K>>;

const useAuthorizedContext = (): Ensure<IAuthContext, "user"> => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuthorizedContext cannot be used without Provider!");
  }
  if (context.user === null) {
    throw new Error("useAuthorizedContext cannot be used unauthorized!");
  }

  return context as Ensure<IAuthContext, "user">;
};

export const useAuthContext = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuthContext cannot be used without Provider!");
  }

  return context;
};

export default useAuthorizedContext;
