import React from "react";
import { Helmet } from "react-helmet";

interface AbsoluteLinkProps {
  title?: string;
  description?: string;
  image?: string;
  twitterPlayer?: string;
}

const Helm: React.FC<AbsoluteLinkProps> = ({
  title,
  description,
  image,
  twitterPlayer,
}) => {
  const desc = description?.replace(/\n/g, " ");

  // TODO: maybe add translated meta tags
  return (
    <Helmet>
      {/* Mandatory meta */}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={desc} />}

      {/* Facebook meta */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={desc} />}
      {image && <meta property="og:image" content={image} />}
      <meta property="og:url" content={window.location.href} />

      {/* Twitter meta */}
      <meta
        property="twitter:card"
        content={twitterPlayer ? "player" : "summary"}
      />
      <meta property="twitter:url" content={window.location.href} />
      {title && <meta property="twitter:title" content={title} />}
      {description && <meta property="twitter:description" content={desc} />}
      {image && <meta property="twitter:image" content={image} />}
      {title && <meta property="twitter:image:alt" content={title} />}
      {/* Cannot use react fragment within helmet!!! */}
      {twitterPlayer && (
        <meta property="twitter:player" content={twitterPlayer} />
      )}
      {twitterPlayer && <meta name="twitter:player:width" content="480" />}
      {twitterPlayer && <meta name="twitter:player:height" content="480" />}
    </Helmet>
  );
};

export default Helm;
