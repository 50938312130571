import React from "react";
import {
  detect,
  BrowserInfo,
  SearchBotDeviceInfo,
  BotInfo,
  NodeInfo,
  ReactNativeInfo,
} from "detect-browser";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { LOGO } from "../../../images/imageSources";

interface Props {
  children: any;
}

const ValidBrowser = (
  browser:
    | BrowserInfo
    | SearchBotDeviceInfo
    | BotInfo
    | NodeInfo
    | ReactNativeInfo,
): boolean => {
  switch (browser.name) {
    case "ios": {
      if (parseFloat(browser.version) < 12) {
        return false;
      }
      return true;
    }
    case "safari": {
      if (parseFloat(browser.version) < 11) {
        return false;
      }
      return true;
    }
    default: {
      return true;
    }
  }
};
const StyledDiv = styled.div({});
const StyledHeader = styled.div({
  height: "3em",

  display: "flex",
  justifyContent: "center",
  borderBottom: "1px solid grey",
  margin: "0.7em",
  borderColor: "#bd1a2c",
  img: {
    marginBottom: "1em",
  },
});
const StyledBody = styled.div({
  backgroundColor: "#F4F4F4",
  display: "flex",
  flexDirection: "column",
  width: "90%",
  margin: "auto",
  marginTop: "3em",
  justifyContent: "center",
  textAlign: "center",
  paddingBottom: "2em",
  ".fa": {
    color: "#BBBBBB",
    fontSize: "4rem",
    padding: "0.2em",
  },
  h1: {
    fontSize: "1.4rem",
  },
});
const StyledHor = styled.div({
  display: "flex",
  flexDirection: "row",
  margin: "auto",
  marginTop: "1em",
});
const BrowserCheck: React.FC<Props> = ({ children }) => {
  const browser = detect();
  const { t } = useTranslation();
  if (browser) {
    if (!ValidBrowser(browser)) {
      return (
        <StyledDiv>
          <StyledHeader>
            <img src={LOGO} alt="latviesi.com" />
          </StyledHeader>
          <StyledBody>
            <StyledHor>
              <i className="fa fa-chrome" />
              <i className="fa fa-firefox" />
              <i className="fa fa-safari" />
              <i className="fa fa-internet-explorer" />
            </StyledHor>
            <h1>{t("InfoBanners.BrowserNotSupported")}</h1>
            <p>{t("InfoBanners.UpdateYourBrowser")}</p>
          </StyledBody>
        </StyledDiv>
      );
    }
    return children;
  }
  return null;
};

export default BrowserCheck;
