export const MAIN_DESCRIPTION =
  "Latviesi.com ir interneta medijs latviešiem ārpus Latvijas, kas sniedz noderīgu informāciju un nodrošina kopienas komunikācijas platformu latviešiem visā pasaulē, ar mērķi palīdzēt latvietības stiprināšanā, ikdienas sadzīvē un strādājot, studējot vai ceļojot ārpus Latvijas.";

export const MAIN_TITLE = "Latviesi.com";

export const GALLERY_TITLE = "Attēlu Galerijas / Latviesi.com";
export const GALLERY_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Attēlu Galerijas». Jaunākie foto stāsti par un ap latviešiem pasaulē.";

export const OFFERS_TITLE = "Piedāvājumi / Latviesi.com";
export const OFFERS_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Piedāvajumi». Jaunākie piedāvājumi un reklāmraksti.";

export const MADE_IN_LATVIA_TITLE = "Latvijā radīts / Latviesi.com";
export const MADE_IN_LATVIA_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Latvijā radīts».";
export const LATVIANS_ABROAD_TITLE = "Latvijas pēdas pasaulē / Latviesi.com";
export const LATVIANS_ABROAD_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Latvijas pēdas pasaulē».";

export const MY_PROFILE_TITLE = "Mans Profils / Latviesi.com";

export const VIDEO_TITLE = "Video / Latviesi.com";
export const VIDEO_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Video». Video stāsti par un ap latviešiem pasaulē.";

export const DIASPORE_TITLE = "Diasporas Mediju Jaunumi / Latviesi.com";
export const DIASPORE_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Diasporas Mediju Jaunumi». Jaunākās diasporas mediju vienātās redakcijas ziņas un jaunumi.";

export const EVENTS_TITLE = "Notikumi / Latviesi.com";
export const EVENTS_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Notikumi». Jaunākie diasporas organizāciju rīkotie pasakumi un notikumi.";

export const NEWS_TITLE = "Jaunumi / Latviesi.com";
export const NEWS_DESCRIPTION =
  "Portāla latviesi.lv ziņu sadaļa. Jaunākās ziņas un jaunumi par un ap latviešiem pasaulē.";

export const ORGANIZATIONS_TITLE = "Diasporas Organizācijas / Latviesi.com";
export const ORGANIZATIONS_DESCRIPTION =
  "Latviešu diasporas organizāciju datubāze.";

export const COMPANIES_TITLE = "Uzņēmumi / Latviesi.com";
export const COMPANIES_DESCRIPTION = "Latviešu diasporas uzņēmumu datubāze.";

export const ORG_NEWS_TITLE = "Organizāciju Jaunumi / Latviesi.com";
export const ORG_NEWS_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Organizāciju Jaunumi». Jaunākās diasporas organizāciju ziņas un jaunumi.";

export const SEARCH_TITLE = "Meklēt Portālā / Latviesi.com";
export const SEARCH_DESCRIPTION = MAIN_DESCRIPTION;

export const REGION_NEWS_TITLE = "Reģionu Jaunumi / Latviesi.com";
export const REGION_NEWS_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Reģionu Jaunumi». Jaunākās reģionu ziņas un jaunumi.";
export const MEDIA_LINKS_TITLE = "Mediju saites";
export const MEDIA_LINKS_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Ārējās ziņas». Jaunākās ārējo mediju ziņas un jaunumi.";
export const MEDIA_SOURCE_TITLE = "Mediju avoti";
export const MEDIA_SOURCE_DESCRIPTION =
  "Ziņu portāla latviesi.lv sadaļa «Ārējie mediju avoti». Jaunākie ārējo mediju avoti.";
