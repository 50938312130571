import {
  CognitoUser,
  CognitoUserSession,
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUserAttribute,
  CognitoUserPool,
  ISignUpResult,
  ICognitoUserAttributeData,
  UserData,
} from "amazon-cognito-identity-js";

export const getSessionPromise = async (
  user: CognitoUser,
): Promise<CognitoUserSession | null> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.getSession((err: null, session: CognitoUserSession | null) => {
      if (err) return reject(err);
      return resolve(session);
    });
  });

export const authenticateUserPromise = async (
  user: CognitoUser,
  authDetails: AuthenticationDetails,
): Promise<CognitoUserSession> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.authenticateUser(authDetails, {
      async onSuccess(result: CognitoUserSession) {
        return resolve(result);
      },
      onFailure(error) {
        return reject(error);
      },
    });
  });

export const refreshSessionPromise = async (
  refreshToken: CognitoRefreshToken,
  user: CognitoUser,
): Promise<CognitoUserSession | null> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.refreshSession(
      refreshToken,
      (err: null, session: CognitoUserSession | null) => {
        if (err) return reject(err);
        return resolve(session);
      },
    );
  });

export const resendConfirmationCodePromise = async (
  user: CognitoUser,
): Promise<void> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.resendConfirmationCode((err) => {
      if (err) {
        return reject(err);
      }
      return resolve();
    });
  });

export const signUpPromise = async (
  pool: CognitoUserPool,
  username: string,
  password: string,
  attrs: CognitoUserAttribute[],
): Promise<ISignUpResult> =>
  new Promise((resolve, reject) => {
    pool.signUp(
      username,
      password,
      attrs,
      [],
      (err?: Error, result?: ISignUpResult) => {
        if (err || !result) return reject(err);
        return resolve(result);
      },
    );
  });

export const confirmRegistrationPromise = async (
  user: CognitoUser,
  code: string,
): Promise<void> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.confirmRegistration(code, true, (err) => {
      if (err) {
        return reject(err);
      }
      return resolve();
    });
  });

export const changePasswordPromise = async (
  user: CognitoUser,
  oldPassword: string,
  newPassword: string,
): Promise<void> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.changePassword(oldPassword, newPassword, (err) => {
      if (err) {
        return reject(err);
      }
      return resolve();
    });
  });

export const forgotPasswordPromise = async (user: CognitoUser): Promise<any> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.forgotPassword({
      onSuccess(data) {
        return resolve(data);
      },
      onFailure(err) {
        return reject(err.message || JSON.stringify(err));
      },
    });
  });

export const confirmPasswordPromise = async (
  user: CognitoUser,
  newPassword: string,
  code: string,
): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.confirmPassword(code, newPassword, {
      onSuccess(data) {
        return resolve(data);
      },
      onFailure(err) {
        return reject(err.message || JSON.stringify(err));
      },
    });
  });

export const deleteUserPromise = async (
  user: CognitoUser,
): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.deleteUser((err, res) => {
      if (err) {
        return reject(err);
      }
      return resolve(res);
    });
  });

export const updateAttributesPromise = async (
  user: CognitoUser,
  attributes: (CognitoUserAttribute | ICognitoUserAttributeData)[],
): Promise<"SUCCESS"> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.updateAttributes(attributes, (err, res) => {
      if (err) {
        return reject(err);
      }
      return resolve(res as "SUCCESS");
    });
  });

export const getUserDataPromise = async (
  user: CognitoUser,
): Promise<UserData> =>
  new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.getUserData((err, res) => {
      if (err || !res) {
        return reject(err);
      }
      return resolve(res);
    });
  });
