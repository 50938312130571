import {
  handleApiPost,
  AWSurl,
  handleApiImageDelete,
  handleApiDeletes,
  BackendUrl,
  handleApiGet,
} from "./api";

const AWSImageApi = `${AWSurl}/image`;

export function UploadUserImage(image: any) {
  return handleApiPost(AWSImageApi, image);
}

export function DeleteUserImage(imageUrl: string) {
  return handleApiImageDelete(AWSImageApi, imageUrl);
}

// new BE

export function deleteMe(token: string) {
  const headers: Record<string, string> = {};
  headers.Authorization = token;
  const body: Record<string, string> = {};
  return handleApiDeletes(`${BackendUrl}/user/delete-me`, body, headers);
}
export function sendWelcomeNotification(
  token: string,
  opts: {
    existingOrgs: string[];
    existingCompanies: string[];
    existingMedia: string[];
  },
) {
  const headers: Record<string, string> = {};
  headers.Authorization = token;
  return handleApiPost(
    `${BackendUrl}/user/welcome-notification`,
    opts,
    headers,
  );
}

export function getMyUserGroups(token: string) {
  const headers: Record<string, string> = {};
  headers.Authorization = token;
  return handleApiGet(`${BackendUrl}/user/my-usergroups`, headers);
}
