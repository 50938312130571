import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Loader } from "semantic-ui-react";
import { ThemeProvider } from "styled-components";
import ErrorBoundary from "components/organisms/ErrorBoundary/ErrorBoundary";
import { ToastContainer } from "react-toastify";
import GlobalContextProvider from "context/GlobalContextProvider";
import { UserDataContextProvider } from "context/UserDataContext";
import { ScriptContextProvider } from "context/ScriptContext";
import AuthContextProvider from "context/AuthContextProvider";
import OrgSlugContextProvider from "context/OrgSlugContextProvider";

import i18n from "./locales/i18n";
import * as serviceWorker from "./serviceWorker";
import BrowserCheck from "./components/organisms/BrowserCheck/BrowserCheck";
import theme from "./styles/theme";
import { MAIN_DESCRIPTION, MAIN_TITLE } from "./constants/seo";
import Helm from "./components/atoms/Helm/Helm";

import "./index.css";
import "semantic-ui-css/semantic.min.css";
import "flatpickr/dist/themes/material_red.css";

const App = lazy(() => import("./App"));

ReactDOM.render(
  <ScriptContextProvider>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Loader />}>
        <BrowserCheck>
          <ThemeProvider theme={theme}>
            <Helm title={MAIN_TITLE} description={MAIN_DESCRIPTION} />
            <ErrorBoundary main>
              <ToastContainer />
              <BrowserRouter>
                <GlobalContextProvider>
                  <AuthContextProvider>
                    <UserDataContextProvider>
                      <OrgSlugContextProvider>
                        <App />
                      </OrgSlugContextProvider>
                    </UserDataContextProvider>
                  </AuthContextProvider>
                </GlobalContextProvider>
              </BrowserRouter>
            </ErrorBoundary>
          </ThemeProvider>
        </BrowserCheck>
      </Suspense>
    </I18nextProvider>
  </ScriptContextProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
