import React, { ErrorInfo } from "react";

import ErrorBanner from "../../molecules/InfoBanners/ErrorBanner";

interface Props {
  main?: boolean;
  test?: boolean;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO error logs
    console.error("Component Error", error.message, errorInfo);
  }

  render() {
    const { children, main, test } = this.props;
    const { hasError } = this.state;
    if (hasError || test) {
      return <ErrorBanner main={main} />;
    }

    return children;
  }
}

export default ErrorBoundary;
