import React from "react";

export type TComponentRef =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "div"
  | "span";
export interface DynamicRefComponentProps {
  componentRef: TComponentRef;
  className?: string; // to style with styled components
  id?: string;
}

const DynamicRefComponent: React.FC<DynamicRefComponentProps> = ({
  componentRef,
  className,
  children,
  id,
}) => {
  const Component = componentRef;

  return (
    <Component className={className} id={id}>
      {children}
    </Component>
  );
};

export default DynamicRefComponent;
