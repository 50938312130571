import { IOrgSlug } from "api/types/TODO";
import React, { useContext, Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { getOrgSlugs } from "api/new/getItemApi";

import "moment/locale/lv";

export interface IOrgSlugContext {
  loading: boolean;
  slugs: IOrgSlug[];
}

const OrgSlugContext = React.createContext<IOrgSlugContext>({
  loading: true,
  slugs: [],
});

export const useOrgSlugContext = (): IOrgSlugContext => {
  const context = useContext(OrgSlugContext);
  if (context === null) {
    throw new Error("useOrgSlugContext cannot be used without Provider!");
  }

  return context;
};

class OrgSlugContextProvider extends Component<
  WithTranslation,
  IOrgSlugContext
> {
  state: IOrgSlugContext = {
    slugs: [],
    loading: true,
  };

  async componentDidMount() {
    try {
      const slugs = await getOrgSlugs();
      this.setState({ slugs, loading: false });
    } catch (err) {
      this.setState({ loading: false });
      console.error("Failed to get orgslugs", err);
    }
  }

  render() {
    const { slugs, loading } = this.state;
    const { children } = this.props;

    return (
      <OrgSlugContext.Provider
        value={{
          slugs,
          loading,
        }}
      >
        {children}
      </OrgSlugContext.Provider>
    );
  }
}

export default withTranslation()(OrgSlugContextProvider);
