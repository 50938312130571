import { ILinkEntityForm } from "api/types/LinkEntity";

import {
  handleApiPost,
  BackendUrl,
  handleApiPut,
  definedRouteTypes,
  handleApiGet,
} from "../api";

import { CATEGORIES } from "constants/categories";

// TODO better way to handle authorization

export async function publishMediaLink(token: string, url: string) {
  const endpoint = token
    ? definedRouteTypes.mediaLinks
    : `${definedRouteTypes.mediaLinks}/anonymous`;
  const headers: Record<string, string> = {};
  if (token) {
    headers.Authorization = token;
  }
  return handleApiPost(
    `${BackendUrl}/${endpoint}?url=${encodeURIComponent(url)}`,
    undefined,
    headers,
  );
}

export async function updateMediaLink(token: string, data: ILinkEntityForm) {
  const endpoint = token ? "" : "/anonymous";

  // Due to elastic search category must also be placed in subcategories.
  if (data.category && data.subCategories) {
    // 1. parsed.subCategories is nonextensible
    const newSubs = [...data.subCategories];

    // 2. omitting any categories from subcategories
    const allCategories = Object.values(CATEGORIES);
    data.subCategories.forEach((sub, idx) => {
      if (allCategories.find((cat) => sub === cat)) {
        newSubs.splice(idx, 1);
      }
    });

    // 3. adding current category to subcategories
    newSubs.push(data.category);
    // eslint-disable-next-line no-param-reassign
    data.subCategories = newSubs;
  }

  const body: Required<Omit<ILinkEntityForm, "imagePointer">> = {
    type: definedRouteTypes.mediaLinks,
    slug: data.slug,
    title: data.title || "",
    imageUrl: data.imageUrl || "",
    category: data.category || null,
    countries: data.countries || [],
    region: data.region || null,
    subCategories: data.subCategories || [],
    show: data.show || false,
  };
  const headers: Record<string, string> = {};
  if (token) {
    headers.Authorization = token;
  }
  return handleApiPut(
    `${BackendUrl}/${definedRouteTypes.mediaLinks}${endpoint}`,
    body,
    headers,
  );
}

export async function rateMediaSource(
  token: string,
  rating: number,
  mediaSourceSlug: string,
) {
  const body = {
    rating,
    mediaSourceSlug,
  };

  const headers: Record<string, string> = {};
  headers.Authorization = token;

  return handleApiPut(`${BackendUrl}/rateMediaSource`, body, headers);
}

export async function getAllUserRatings(token: string) {
  const headers: Record<string, string> = {};
  headers.Authorization = token;
  if (!token) return [];

  return handleApiGet(`${BackendUrl}/getAllUserRatings`, headers);
}
