import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "moment/locale/lv";
import "moment/locale/en-gb";
import "moment/locale/de";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import GlobalStateContext from "./GlobalContext";

import definedLocales from "../locales/definedLocales";

import { LANG_COOKIE } from "constants/cookies";

interface IGlobalContextState {
  locale: definedLocales;
  translationError: boolean;
}

class GlobalContextProvider extends Component<
  WithTranslation,
  IGlobalContextState
> {
  state = {
    // / TODO implement language detection
    locale: definedLocales.latvian,
    translationError: false,
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const { translationError } = this.state;
    const { t } = this.props;
    if (prevState !== this.state) {
      if (translationError) {
        toast.error(t("TranslationError"));
      }
    }
  }

  handleSetLocale = async (language: definedLocales) => {
    const { i18n } = this.props;
    await i18n?.changeLanguage(language);
    this.setState({ locale: language, translationError: false });
    Cookies.set(LANG_COOKIE, language);
  };

  handleSetTranslationError = () => {
    const { translationError } = this.state;
    if (!translationError) {
      this.setState({ translationError: true });
    }
  };

  render() {
    const { locale } = this.state;
    const { children } = this.props;

    return (
      <GlobalStateContext.Provider
        value={{
          locale: {
            currentLocale: locale,
            defaultLocale: definedLocales.latvian,
            setLocale: this.handleSetLocale,
            setTranslationError: this.handleSetTranslationError,
          },
        }}
      >
        {children}
      </GlobalStateContext.Provider>
    );
  }
}

export default withTranslation()(GlobalContextProvider);
